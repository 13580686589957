<template>
  <div class="timeline"
    v-dragscroll
    :class="{
      'timeline--text': stepsData,
      'timeline--numeric': !stepsData,
      'timeLine--lastoright' : stepsData
    }"
  >
    <template v-if="stepsData">
      <div class="timeline-step timeline-step--text"
        v-for="(step, i) in stepsData" :key="i"
        :class="{
          'timeline-step--done': i < index,
          'timeline-step--doing': i === index,
          'timeline-step--todo': i > index,
          'timeline-step--grouping': grouping
        }"
      >
        <span>{{ step }}</span>
      </div>
    </template>
    <template v-else>
      <div class="timeline-step timeline-step--numeric"
        v-for="i in total" :key="i"
        :class="{
          'timeline-step--done': i < index,
          'timeline-step--doing': i === index,
          'timeline-step--todo': i > index,
        }"
      >
        <span>{{ i }}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Timeline',

  props: {
    index: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 7,
    },
    stepsData: {
      type: Array,
    },
    lastStepToRight: {
      type: Boolean,
      default: true,
    },
    grouping: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
/* TIMELINE */

$timeline-step-size: 2.6rem;
$timeline-dash-size: $gutter-half;
$timeline-dash-margin: $gutter-half / 3;

.timeline {
  display: flex;
  // flex-wrap: wrap;
  gap: ($timeline-dash-size + 2 * $timeline-dash-margin);
  overflow: hidden;

  &.timeline--text {
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      top: 50%;
      background-color: $color-gray-light;
    }
  }

  &.timeLine--lastoright{
      .timeline-step:last-child{
        margin-left: auto;
      }
  }
}

.timeline-step {
  position: relative;
  background-color: white;
  text-align: center;
  line-height: $timeline-step-size;
  font-weight: $font-weight-semibold;
  color: $color-primary;
  transition: all 0.25s;

  &.timeline-step--text {
    height: $timeline-step-size;
    @include h-padding($gutter-quarter);
    border-radius: $border-radius-base;

    &:not(:last-child) {
      // border-right: $timeline-dash-margin solid $color-gray-lightestest;
      &::before {
        content: "";
        display: block;
        position: absolute;
        right: - $timeline-dash-margin;
        top: 0;
        width: $timeline-dash-margin;
        height: 100%;
        background-color: $color-gray-lightestest;
      }
    }

    &:not(:first-child) {
      // border-left: $timeline-dash-margin solid $color-gray-lightestest;
      &::after {
        content: "";
        display: block;
        position: absolute;
        left: - $timeline-dash-margin;
        top: 0;
        width: $timeline-dash-margin;
        height: 100%;
        background-color: $color-gray-lightestest;
      }
    }

    > span {
      position: relative;
      display: inline-block;
      padding-left: $gutter-quarter + ($timeline-step-size / 2);
      white-space: nowrap;

      // Step dot
      &::before {
        content: "";
        display: block;
        position: absolute;
        @include size($timeline-step-size / 2);
        background-color: currentColor;
        border-radius: 50%;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }

  }

  &.timeline-step--numeric {
    @include size($timeline-step-size);
    border-radius: 50%;

    // Line between steps
    &:not(:last-child) {
      &::after {
        content: "";
        position: absolute;
        display: block;
        width: $timeline-dash-size;
        height: 1px;
        left: $timeline-step-size + $timeline-dash-margin;
        top: 50%;
        background-color: $color-gray-light;
      }
    }
  }
}

.timeline-step--doing {
  background-color: $color-primary;
  color: white;
  &.timeline-step--grouping {
    background-color: $color-protocol-grouping;
    color: white;
  }
}

.timeline-step--grouping {
  color: $color-protocol-grouping;
}

</style>
