<template>
  <Section class="section--border-bottom bg-gray-lightestest">
    <Container>
      <Timeline
        :index="step"
        :steps-data="[
          'Modalités',
          'Entités',
          'Récapitulatif',
        ]"
      />
    </Container>
  </Section>
  <Section>
    <List
      :api="list.api"
      :header="list.headers"
      :items="list.col"
      :item-key="list.key"
      :disableActions="true"
      :disableGroupActions="true"
      loopOn="enfants"
      :default-selected-items="selectedItems"
      @selectedItems="selectItems"
    >
    </List>
  </Section>

</template>

<script>
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Timeline from '@/components/layout/Timeline.vue'
import List from '@/components/list/List.vue'

export default {
  name: 'EntitiesAddSubview',

  components: {
    List,
    Section,
    Container,
    Timeline,
  },

  props: {
    pageTitle: {
      type: String,
    },
    validationSchema: {
      type: Array,
      default: undefined,
    },
  },

  data() {
    return {
      list: {
        api: {
          endpoint: '',
          params: {
            sort: 'id.ASC',
            page: null,
            limit: null,
            filters: null,
          },
        },
        headers: [
          { label: 'Entités' },
        ],
        col: ['nom'],
        key: 'id',
      },
      protocol: {},
      step: 1,
      routeName: this.$route.name,
      previousRouteName: null,
      errors: {},

      selectedItems: [],
    }
  },

  emits: [
    'set-step',
    'populate',
    'set-route',
  ],

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.previousRouteName = from.name
    })
  },

  computed: {
    isBpe: {
      get() {
        return (this.protocol.bpe) ?? false
      },
      set(v) {
        this.protocol = v
        this.componentKey += 1
      },
    },
  },

  async mounted() {
    this.emitter.emit('open-loader')
    const routeParams = {}

    if (this.$route.params.id) {
      routeParams.id = this.$route.params.id
    }

    this.$emit('set-step', this.step)
    this.$emit('set-route', {
      routeName: this.routeName,
      previousRouteName: 'protocolModalities',
      nextRouteName: 'protocolSummary',
      routeParams,
    })
    this.protocol = await this.getProtocol()
    this.getChildEntity()
    this.emitter.emit('close-loader')
  },

  methods: {
    selectItems(event) {
      this.selectedItems = event.selected

      if (
        typeof this.$store.state.protocol.entities !== 'undefined'
        && Object.keys(this.$store.state.protocol.entities).length > 0
        && !('selected' in event)
      ) {
        this.selectedItems = this.$store.state.protocol.entities
      }

      this.$store.dispatch('protocol/selectEntities', this.selectedItems)
    },

    async getProtocol() {
      const response = await this.fetchService.get(`protocole/${this.$route.params.id}`)
      const protocol = await response.data
      this.isBpe = protocol

      return protocol
    },

    getChildEntity() {
      const entityId = this.$store.state.auth.user.data.entite.id

      this.list.api.endpoint = `entite/${entityId}`
    },

    setData() {
      //
    },
  },
}
</script>

<style scoped>
</style>
